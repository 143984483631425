<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                headline="journaleintrag"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_journal.delete && editable"
                  >
                    <font-awesome-icon icon="fa-duotone fa-trash" />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-8">
                    <single-select-person
                      id="ansprechpartner"
                      :person="
                        journaleintrag.person
                          ? journaleintrag.person
                          : journaleintrag.verantwortlicher
                      "
                      label="Primärer Kontakt"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setPerson"
                      :tabindex="1"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <input
                        v-model="journaleintrag.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <single-select-person
                      id="verantwortlicher"
                      :person="journaleintrag.verantwortlicher"
                      :label="$t('global.Responsible')"
                      :allowOpen="true"
                      :editable="editable"
                      :required="true"
                      @confirmed="setVerantwortlicher"
                      :tabindex="6"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="journaleintrag.datum"
                        :disabled="!editable"
                        placeholder=" "
                        :show-icon="true"
                        @update="(val) => (journaleintrag.datum = val)"
                      />
                      <label>Datum</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <time-field
                      label="Uhrzeit"
                      placeholder=""
                      :readonly="!editable"
                      :initial="journaleintrag.zeit"
                      :tabindex="3"
                      @update="(val) => (journaleintrag.zeit = val)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Zordnung -->

          <container-headline
            headline="Kategorisierung"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="journaleintrag.typ"
                        label="bezeichnung"
                        :disabled="!editable"
                        :options="typen"
                        :tabindex="6"
                        placeholder="Typ"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.type") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-select
                        v-model="journaleintrag.status"
                        label="bezeichnung"
                        :disabled="!editable"
                        :options="stati"
                        :tabindex="7"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Zuordnung -->
          </div>

          <!-- ENDE Rechte Seite -->
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            headline="Beschreibung"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <ckeditor
                        v-model.trim="journaleintrag.beschreibung"
                        :editor="editor"
                        :disabled="!editable"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="journaleintrag-loeschen-modal"
      @confirmed="journalEintragLoeschen"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField";
import ClassicEditor from "/custom_ckeditor/build/ckeditor";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_JOURNAL } from "@/store/journal/actions.type";

export default {
  name: "Lead",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    TimeField,
    LoeschenModal,
    SingleSelectPerson,
    UnsavedChangesModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return `Journaleintrag`;
      },
    };
  },
  mixins: [page, ChangeWatcherNew],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      editor: ClassicEditor,
      journaleintrag: {
        person: null,
        verantwortlicher: null,
      },
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },

    typen() {
      if (this.$store) return this.$store.state.journal.journaleintragtypen;
      return [];
    },
    stati() {
      if (this.$store) return this.$store.state.journal.journaleintragstati;
      return [];
    },
  },
  watch: {},
  created() {
    this.loading = true;

    this.loadjournaleintrag();
  },
  mounted: function () {
    if (this.typen.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_JOURNAL}`);
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;
  },
  methods: {
    loadjournaleintrag() {
      if (this.id) {
        Api.get("journal/journaleintrag/", { params: { id: this.id } }).then(
          (response) => {
            this.initializeJournalEintrag(response.data);

            this.loading = false;
          }
        );
      } else {
        this.journaleintrag.verantwortlicher =
          this.angemeldeterMitarbeiter.person;

        this.editable = true;
        this.loading = false;
      }
    },

    abbrechen() {
      this.$router.push({ name: "journaleintrag-liste" });
    },

    initializeJournalEintrag(journaleintrag) {
      if (journaleintrag) {
        this.journaleintrag = journaleintrag;

        this.editable = true;

        if (this.berechtigungen.m_journal.update == false || this.isDozent)
          this.editable = false;

        this.initializeChangewatcher(["journaleintrag"]);
      } else {
        console.error("journaleintrag ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;
      var json = this.journaleintrag;

      if (!this.id) {
        Api.post("journal/journaleintrag/", json)
          .then((response) => {
            this.initializeJournalEintrag(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Journal Eintrag erfolgreich gespeichert.",
            });

            this.$router.replace({
              name: "journaleintrag",
              params: { id: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("journal/journaleintrag/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeJournalEintrag(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Journal Eintrag erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "journaleintrag-loeschen-modal");
    },

    journalEintragLoeschen() {
      Api.delete("journaleintrag/journaleintrag/", { data: [this.id] }).then(
        () => {
          this.$router.push({ name: "journaleintrag-liste" });
        }
      );
    },

    setVerantwortlicher(person) {
      this.journaleintrag.verantwortlicher = person;
    },

    setPerson(person) {
      this.journaleintrag.ansprechpartner = person;
    },
  },
};
</script>

<style></style>
