<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid || !portalinfoValid"
                    @click="speichern(invalid)"
                    @shortkey="speichern(invalid)"
                    v-tooltip
                    :title="
                      invalid
                        ? $t('global.notallfieldsfilledcorrectly')
                        : !portalinfoValid
                        ? $t('global.portalinfonotvalid')
                        : ''
                    "
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_kurzveranstaltung.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <kurzveranstaltung-stammdaten
            :kurzveranstaltung="kurzveranstaltung"
            :editable="editable"
            :isTemplate="false"
          />
        </div>

        <div v-if="kurzveranstaltungid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 0,
                      },
                    }"
                    >{{ $t("global.registration") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="berechtigungen.m_dozierende.read">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 1,
                      },
                    }"
                    >{{ $t("global.lecturerbooking") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 2,
                      },
                    }"
                    >{{ $t("global.appointment") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="kurzveranstaltung.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 3 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 3,
                      },
                    }"
                    >{{ $t("global.clearingitems") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="kurzveranstaltung.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 4 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 4,
                      },
                    }"
                    >{{ $t("global.portalinformation") }}</router-link
                  >
                </li>
                <li class="nav-item" v-if="kurzveranstaltung.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 5 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 5,
                      },
                    }"
                    >{{ $t("global.registrationform") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 6 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 6,
                      },
                    }"
                    >{{
                      $t("global.additionalregistrationinformation")
                    }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 7 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 7,
                      },
                    }"
                    >{{ $t("global.dashboard_presence") }}</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 8 }"
                    :to="{
                      name: 'Kurzveranstaltung',
                      params: {
                        kurzveranstaltungid: kurzveranstaltungid,
                        anzeige: 8,
                      },
                    }"
                    >{{ $t("global.survey") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <!-- Start Portalinfos -->
                <div v-show="anzeige === 0">
                  <anmeldungsliste
                    :veranstaltung="kurzveranstaltungid"
                    :klassenkuerzel="kurzveranstaltung.kuerzel"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    :key="anmeldungslisteKey"
                  />
                </div>
                <div
                  v-show="anzeige === 1"
                  v-if="berechtigungen.m_dozierende.read"
                >
                  <dozentenbuchungsliste
                    :veranstaltung="kurzveranstaltungid"
                    :shown="anzeige == 1 ? true : false"
                    :editable="editable"
                    :getListeKey="getListeKey"
                    @dbUpdated="terminlisteKey++"
                  />
                </div>
                <div v-show="anzeige === 2">
                  <terminliste
                    :veranstaltung="kurzveranstaltung"
                    :shown="anzeige == 2 ? true : false"
                    :editable="editable"
                    :key="terminlisteKey"
                    :anzahl_anmeldungen="anzahl_anmeldungen"
                    @updated="terminlisteKey++"
                  />
                </div>
                <div v-show="anzeige === 3 && kurzveranstaltung.portalanzeige">
                  <verrechnungspositionen
                    :editable="editable"
                    :shown="anzeige === 3"
                    :veranstaltung="kurzveranstaltungid"
                    :last-abacus-export="kurzveranstaltungid.last_abacus_export"
                    :kuerzel="kurzveranstaltung.kuerzel"
                    :isVeranstaltung="true"
                    :isShortEvent="true"
                  />
                </div>
                <div v-show="anzeige === 4 && kurzveranstaltung.portalanzeige">
                  <portalinformationen
                    :lehrgang="kurzveranstaltung"
                    :isKurzveranstaltung="true"
                    :portalinfoId="portalinfoId"
                    :shown="anzeige == 4 ? true : false"
                    :editable="editable"
                    @portalinfoChanged="portalinfoChanged"
                    @portalinfoValidChanged="portalinfoValidChanged"
                    @portalinfoInitialized="handlePortalinfoInitialized"
                    @portalinfoFirstInitialized="
                      initializeChangewatcher(['kurzveranstaltung'])
                    "
                  />
                </div>
                <!-- Ende Portalinfos -->
                <div v-show="anzeige === 5 && kurzveranstaltung.portalanzeige">
                  <anmeldeformular
                    :lehrgang="kurzveranstaltung"
                    :shown="anzeige == 5 ? true : false"
                    :editable="editable"
                    :mailvorlagen="mailvorlagen"
                    :isVeranstaltung="true"
                  />
                </div>
                <div v-show="anzeige === 6">
                  <zusatzangebote
                    :klassenid="kurzveranstaltungid"
                    :shown="anzeige == 6 ? true : false"
                    :editable="editable"
                  />
                </div>
                <div v-show="anzeige === 7">
                  <anwesenheit
                    :shown="anzeige == 7 ? true : false"
                    :editable="editable"
                    :kurzveranstaltungid="kurzveranstaltungid"
                  />
                </div>
                <div v-if="anzeige === 8">
                  <umfrage-reiter
                    :veranstaltung="kurzveranstaltung"
                    :veranstaltungId="kurzveranstaltungid"
                    :shown="anzeige == 8 ? true : false"
                    :editable="editable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <unsaved-changes-modal
        @discard="handleDiscard"
        @stay="handleStay"
        :saveFunction="invalid == true ? null : speichern"
      />
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="kurzveranstaltung-loeschen-modal"
      @confirmed="kurzveranstaltungLoeschen"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import AppApi from "@/AppApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import ChangeWatcherNew from "@/mixins/ChangewatcherNew";

import KurzveranstaltungStammdaten from "@/components/Bildung/Kurzveranstaltung/Stammdaten";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import UnsavedChangesModal from "@/components/Modals/UnsavedChangesModal.vue";
import Portalinformationen from "@/components/Reiter/Portalinformationen";
import Anmeldungsliste from "@/components/Reiter/Anmeldungsliste";
import Terminliste from "@/components/Reiter/Terminliste";
import Verrechnungspositionen from "@/components/Reiter/Verrechnungspositionen.vue";
import Dozentenbuchungsliste from "@/components/Reiter/Dozentenbuchungsliste";
import Anmeldeformular from "@/components/Reiter/Anmeldeformular";
import Zusatzangebote from "@/components/Reiter/Zusatzangebote";
import Anwesenheit from "@/components/Reiter/Anwesenheit";
import UmfrageReiter from "@/components/Reiter/Questionaireliste";
import { isEmptyObject } from "jquery";

export default {
  name: "Kurzveranstaltung",
  components: {
    HeadMenu,
    Navbar,
    KurzveranstaltungStammdaten,
    LoeschenModal,
    UnsavedChangesModal,
    Portalinformationen,
    Anmeldungsliste,
    Terminliste,
    Verrechnungspositionen,
    Dozentenbuchungsliste,
    Anmeldeformular,
    Zusatzangebote,
    Anwesenheit,
    UmfrageReiter,
  },

  mixins: [page, ChangeWatcherNew],
  props: {
    kurzveranstaltungid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      anmeldungslisteKey: 0,
      terminlisteKey: 0,
      anzahl_anmeldungen: 0,
      getListeKey: 0,
      fromRoute: null,
      kurzveranstaltung: {
        veranstaltungsstatus: null,
        leitung: { personName: this.$t("global.notlinked") },
        kontakt: { personName: this.$t("global.notlinked") },
        verantwortlich: { personName: this.$t("global.notlinked") },
        portalinfo: { id: null },
        portalanzeige: true,
        sprachen: [],
        vorbereitung: "",
      },
      portalinfoValid: true,
    };
  },
  computed: {
    navbarTitel() {
      if (this.kurzveranstaltung.id)
        return (
          this.$t("global.shortevent") +
          " #" +
          this.kurzveranstaltung.veranstaltungsnummer +
          (this.kurzveranstaltung.bezeichnung
            ? " - " + this.kurzveranstaltung.bezeichnung
            : "")
        );
      else return this.$t("global.newshortevent");
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    mailvorlagen: {
      get() {
        return this.$store.state.veranstaltung.mailvorlagen;
      },
    },
    portalinfoId() {
      if (this.kurzveranstaltung.portalinfo_id)
        return this.kurzveranstaltung.portalinfo_id;
      return null;
    },
  },
  watch: {
    sprachen: {
      deep: true,
      handler(val) {
        if (val) {
          if (this.kurzveranstaltung.sprachen.length === 0) this.setLanguage();
        }
      },
    },
  },
  created() {
    this.fromRoute = this.$route.params.fromRoute;
    if (this.kurzveranstaltungid) {
      this.loading = true;

      AppApi.get("kurzveranstaltung/", {
        params: { id: this.kurzveranstaltungid },
      })
        .then((response) => {
          this.initializeKurzveranstaltung(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.editable = true;
      this.setLanguage();
    }
  },
  mounted() {
    this.getListeKey++;
    this.terminlisteKey++;
  },
  methods: {
    portalinfoChanged() {
      this.$set(this.hasChanges, "portalinformationen", true);
    },

    handlePortalinfoInitialized() {
      this.$set(this.hasChanges, "portalinformationen", false);
    },

    setLanguage() {
      const defaultSprache = this.$CONST("CONFIG").DEFAULTLANGUAGE;

      let defaultSprachenObj = this.sprachen.find(
        (sprache) => sprache.id === defaultSprache
      );
      //this.$set(this.kurzveranstaltung, "sprache", defaultSprachenObj);
      this.kurzveranstaltung.sprachen.push(defaultSprachenObj);
    },

    abbrechen() {
      this.$router.push({
        name: this.fromRoute || "Kurzveranstaltungsliste",
      });
    },

    initializeKurzveranstaltung(kurzveranstaltung) {
      if (kurzveranstaltung) {
        this.kurzveranstaltung = kurzveranstaltung;
        this.anzahl_anmeldungen = kurzveranstaltung.anmeldungen;

        if (
          this.kurzveranstaltung.verantwortlich &&
          this.kurzveranstaltung.verantwortlich.length === 0
        ) {
          this.kurzveranstaltung.verantwortlich.push({
            personName: this.$t("global.notlinked"),
          });
        }

        if (!this.kurzveranstaltung.leitung)
          this.kurzveranstaltung.leitung = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.kurzveranstaltung.veranstaltungsstatus)
          this.kurzveranstaltung.veranstaltungsstatus = {
            personName: this.$t("global.notlinked"),
          };

        if (this.kurzveranstaltung.sprachen.length === 0) {
          this.setLanguage();
        }

        this.editable =
          this.kurzveranstaltung.veranstaltungsstatus.id ==
          this.$CONST("LEHRGANGSTATI").PLANNED
            ? true
            : true;

        // TODO: diesen Block entfernen, sobald Backend diesen Flag mitsendet
        if (kurzveranstaltung.portalanzeige) {
          this.kurzveranstaltung.portalanzeige =
            kurzveranstaltung.portalanzeige;
        } else {
          this.kurzveranstaltung.portalanzeige = true;
        }
      } else this.editable = true;

      this.editable = this.berechtigungen.m_kurzveranstaltung.update;

      this.initializeChangewatcher(["kurzveranstaltung"]);
    },

    speichern(invalid) {
      if (this.loading) return;

      if (invalid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.notallfieldsfilledcorrectly"),
        });
        return;
      }

      this.loading = true;
      if (this.kurzveranstaltung.teilnehmer_max === "") {
        this.kurzveranstaltung.teilnehmer_max = null;
      }

      // Wenn Postalinfos vorhanden, diese mitspeichern
      if (this.kurzveranstaltung.portalanzeige && this.portalinfoValid) {
        this.$bus.$emit("savePortalinfo");
      } else if (
        this.kurzveranstaltung.portalanzeige &&
        !this.portalinfoValid
      ) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.portalinfonotvalid"),
        });
      }
      var json = {};

      json.bezeichnung = this.kurzveranstaltung.bezeichnung;
      json.kontaktId = this.kurzveranstaltung.kontakt
        ? this.kurzveranstaltung.kontakt.id
        : null;
      json.kuerzel = this.kurzveranstaltung.kuerzel;
      json.kurzveranstaltungstypId = this.kurzveranstaltung
        .kurzveranstaltungstyp
        ? this.kurzveranstaltung.kurzveranstaltungstyp.id
        : null;
      json.leitungId = this.kurzveranstaltung.leitung
        ? this.kurzveranstaltung.leitung.id
        : null;
      json.sprachenIds =
        this.kurzveranstaltung.sprachen.length > 0
          ? this.kurzveranstaltung.sprachen.map((sprache) => sprache.id)
          : [];
      json.veranstaltungsstatusId = this.kurzveranstaltung.veranstaltungsstatus
        ? this.kurzveranstaltung.veranstaltungsstatus.id
        : null;
      json.verantwortlichId = this.kurzveranstaltung.verantwortlich
        ? this.kurzveranstaltung.verantwortlich.id
        : null;
      json.vorbereitung = this.kurzveranstaltung.vorbereitung;
      json.abschlussId = this.kurzveranstaltung.abschluss
        ? this.kurzveranstaltung.abschluss.id
        : null;
      json.startdatum = this.kurzveranstaltung.startdatum;
      json.enddatum = this.kurzveranstaltung.enddatum;
      json.institutionId = this.kurzveranstaltung.institution
        ? this.kurzveranstaltung.institution.id
        : null;
      json.teilnehmeranzahlMaximal =
        this.kurzveranstaltung.teilnehmeranzahl_maximal;
      json.themaIds = this.kurzveranstaltung.thema_auswahl
        ? this.kurzveranstaltung.thema_auswahl.map((thema) => thema.id)
        : [];
      json.wartelisteZulassen = this.kurzveranstaltung.warteliste_zulassen;
      json.zielgruppenIds = this.kurzveranstaltung.zielgruppen
        ? this.kurzveranstaltung.zielgruppen.map((zielgruppe) => zielgruppe.id)
        : [];
      json.veranstaltungsgruppeId = this.kurzveranstaltung.veranstaltungsgruppe
        ? this.kurzveranstaltung.veranstaltungsgruppe.id
        : null;
      json.veranstaltungskategorieId = this.kurzveranstaltung
        .veranstaltungskategorie
        ? this.kurzveranstaltung.veranstaltungskategorie.id
        : null;

      if (this.kurzveranstaltungid) {
        json.portalinfo = this.kurzveranstaltung.portalinfo
          ? this.kurzveranstaltung.portalinfo
          : null;
        json.anmeldeformular = this.kurzveranstaltung.anmeldeformular
          ? this.kurzveranstaltung.anmeldeformular
          : null;
        json.zusatzangebote = this.kurzveranstaltung.zusatzangebote
          ? this.kurzveranstaltung.zusatzangebote
          : null;
      }
      console.log(json);

      if (!this.kurzveranstaltungid) {
        AppApi.post("kurzveranstaltung/", json)
          .then((response) => {
            this.initializeKurzveranstaltung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text:
                this.$t("global.shortevent") +
                " " +
                this.$t("global.successfullysaved"),
            });

            this.$router.replace({
              name: "Kurzveranstaltung",
              params: { kurzveranstaltungid: response.data.id, anzeige: 0 },
            });

            this.initializeChangewatcher(["kurzveranstaltung"]);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        AppApi.put("kurzveranstaltung/", json, {
          params: { id: this.kurzveranstaltungid },
        })
          .then((response) => {
            this.initializeKurzveranstaltung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text:
                this.$t("global.shortevent") +
                " " +
                this.$t("global.successfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.initializeChangewatcher(["kurzveranstaltung"]);
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "kurzveranstaltung-loeschen-modal");
    },

    kurzveranstaltungLoeschen() {
      // this.disableChangeWatcher();
      AppApi.delete("kurzveranstaltung/", {
        params: { id: this.kurzveranstaltungid },
      }).then(() => {
        this.$router.push({ name: "Kurzveranstaltungsliste" });
      });
    },

    portalinfoValidChanged(newVal) {
      this.portalinfoValid = newVal;
    },
  },
};
</script>

<style></style>
